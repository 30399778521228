import ApplicationController from "./application_controller"
import { patch } from "@rails/request.js"

export default class extends ApplicationController {
  static values = {
    url: String
  }
  static targets = [
    "requiredAction",
    "optionalAction"
  ]

  reloadActions() {
    patch(this.urlValue, {
      body: new FormData(this.element.closest("form")),
      responseKind: "turbo-stream"
    }).then((response) => {
      if (!response.ok) {
        alert(response.response.statusText)
      }
    })
  }

  refresh() {
    // We do not have to do anything if there are no required actions
    if(this.requiredActionTargets.length === 0) {
      return
    }

    this.requiredActionTargets.forEach((action) => {
      // Remove / hide buttons for required actions.
      action.querySelector(".device-action-form__actions").classList.add("hidden")
    })

    // Add `.first-optional` class to first visible optional action only so we can hide move up button.
    // We add class instead of using existing hidden class to prevent conflict with nested form logic.
    this.optionalActionTargets
      .filter(this.isVisible)
      .sort(this.compareActions)
      .forEach((element, index) => {
        if (index === 0) {
          element.classList.add("first-optional")
        } else {
          element.classList.remove("first-optional")
        }
      })
  }

  isVisible(element) {
    return element.offsetParent != null
  }

  compareActions(firstAction, secondAction) {
    const value1 = parseInt(secondAction.querySelector("input[name*='position']").value)
    const value2 = parseInt(firstAction.querySelector("input[name*='position']").value)

    if (value2 === 0) {
      return 1
    }

    return value2 - value1
  }
}
