/*
 * Extends the standard Nested Form controller with the ability to add a custom
 * hidden class (useful for controling styling for delimiters).
 */
import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  static classes = [ "hidden" ]
  static targets = [
    ...super.targets,
    "addButton",
    "contentWhenEmpty",
    "maxItemsCountWarning"
  ]
  static values = {
    ...super.values,
    maxItemsCount: Number,
    removeElements: {
      type: Boolean,
      default: false
    }
  }

  add(event) {
    super.add(event)
    this.updateAddButtonState()
    this.dispatch("add")
  }

  connect() {
    super.connect()
    this.dispatch("connect")
  }

  initialize() {
    super.initialize()

    this.items = []
    this.updateAddButtonState()
  }

  swapItems(itemIndex1, itemIndex2) {
    this.items[itemIndex1].updatePosition(itemIndex2, this.items.length)
    this.items[itemIndex2].updatePosition(itemIndex1, this.items.length)

    const temp = this.items[itemIndex1]
    this.items[itemIndex1] = this.items[itemIndex2]
    this.items[itemIndex2] = temp
  }

  moveUp(position) {
    if (position == 0) {
      return
    }
    this.swapItems(position - 1, position)

    this.dispatch("moveup")
  }

  moveDown(position) {
    if (position == this.items.length - 1) {
      return
    }
    this.swapItems(position, position + 1)

    this.dispatch("movedown")
  }

  registerItem(item) {
    this.items.push(item)
    item.updatePosition(this.items.length - 1, this.items.length)
    if (this.items.length > 1) {
      this.items[this.items.length - 2].updatePosition(this.items.length - 2, this.items.length)
    }

    if (this.items.length == 1 && this.hasContentWhenEmptyTarget) {
      this.contentWhenEmptyTarget.classList.add("hidden")
    }
  }

  removeAndReorder(event, position) {
    this.items.splice(position, 1)
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].updatePosition(i, this.items.length)
    }

    if (this.items.length == 0 && this.hasContentWhenEmptyTarget) {
      this.contentWhenEmptyTarget.classList.remove("hidden")
    }
    this.remove(event)
    this.updateAddButtonState()
  }

  remove(e) {
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper && this.hasHiddenClass) {
      wrapper.classList.add(this.hiddenClass)
    }

    if (this.removeElementsValue) {
      e.preventDefault()
      wrapper.remove()
    }
    else {
      super.remove(e)
    }

    this.dispatch("remove")
  }

  updateAddButtonState() {
    if (this.hasMaxItemsCountValue && this.hasAddButtonTarget) {
      const itemCount = this.element.querySelectorAll(this.wrapperSelectorValue+":not([style*=\"display: none;\"]").length
      if (itemCount >= this.maxItemsCountValue) {
        this.addButtonTarget.disabled = true
        if (this.hasMaxItemsCountWarningTarget) {
          this.maxItemsCountWarningTarget.classList.remove("hidden")
        }
      } else {
        this.addButtonTarget.disabled = false
        if (this.hasMaxItemsCountWarningTarget) {
          this.maxItemsCountWarningTarget.classList.add("hidden")
        }
      }
    }
  }
}
